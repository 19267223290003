import { MDXRemote } from "next-mdx-remote";
import {
  SectionClients,
  ServiceListTabs,
  SectionExperts,
  SectionTestimonials,
  ProductListItem,
} from "../components";
import {
  getAllClients,
  getAllPeople,
  getAllProducts,
  getAllTestimonials,
  getHomeData,
  getServicesGroupedByCategory,
  getServiceSlugs,
  HomePageData,
} from "../lib/api";
import { getGlobalStaticProps } from "../lib/helpers";
import { mdxComponents } from "../lib/mdx";
import {
  Client,
  Service,
  ServiceCategory,
  Person,
  Testimonial,
  Product,
} from "../lib/models";

interface HomeData {
  page: HomePageData;
  clients: Client[];
  experts: Person[];
  testimonials: Testimonial[];
  servicesCount: number;
  services: { category: ServiceCategory; items: Service[] }[];
  products: Product[];
}

export default function Home({ data }: { data: HomeData }) {
  const { clients, services, products, experts, testimonials, page } = data;
  const { why, hero_bg, hero_contentMdx } = page;
  return (
    <>
      {/* Hero */}
      <div
        className="py-lg-18 py-10 bg-auto"
        style={{
          background: `url(${hero_bg})no-repeat, linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%), rgba(221, 218, 255, 0.3)`,
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-7 col-md-12">
              <div className="py-8 py-lg-0 text-center">
                <MDXRemote {...hero_contentMdx} components={mdxComponents} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Products */}
      <div id="products" className="py-8 py-lg-16 bg-light text-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <span className="text-primary mb-3 d-block text-uppercase font-weight-semi-bold ls-lg">
                Products
              </span>
              <h2 className="mb-1 display-4 font-weight-bold">
                Powerful Salesforce Apps
              </h2>
              <p className="mb-8 lead">
                Take the headache out of Salesforce and increase the
                productivity of your team. We have products and services that
                help you get more out of your Salesforce.
              </p>
            </div>
          </div>
          <div className="row">
            {products.map((p, i) => (
              <div key={p.slug} className="col-md-4 mb-4">
                <ProductListItem {...p} />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Testimonials section */}
      <div className="bg-white py-8 py-lg-16">
        <div className="container pb-12">
          <SectionTestimonials testimonials={testimonials} />
        </div>
      </div>

      {/* Why */}
      <div className="bg-light py-8 py-lg-16">
        <div className="container">
          <div className="row mb-8 justify-content-center">
            <div className="col-lg-8 col-md-12 col-12 text-center">
              <h2 className="mb-2 display-4 font-weight-bold">
                Why CloudAnswers
              </h2>
              <p className="lead">
                All sorts of Salesforce services to help your business grow.
              </p>
            </div>
          </div>

          <div className="row">
            {why.map((s, i) => (
              <div key={s.title + i} className="col-lg-3 col-md-6 col-12">
                <div className="mb-4">
                  <div className="display-4 text-primary">
                    <i className={s.icon}></i>
                  </div>

                  <div className="mt-4">
                    <h3>{s.title}</h3>
                    <p className="font-size-md">{s.content}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Clients */}
      <div className="bg-white pt-8 pt-lg-16 pb-0">
        <div className="container">
          <SectionClients clients={clients} />
        </div>
      </div>

      {/* Experts */}
      <div className="py-8 py-lg-16 bg-light">
        <div className="container">
          <SectionExperts experts={experts} />
        </div>
      </div>
    </>
  );
}

export async function getStaticProps() {
  const page = await getHomeData();
  const data: HomeData = {
    clients: await getAllClients(),
    experts: await getAllPeople(),
    testimonials: await getAllTestimonials(),
    products: (await getAllProducts()).sort((a, b) =>
      a.title.localeCompare(b.title)
    ),
    services: await getServicesGroupedByCategory({
      limit: 8,
      includeAll: true,
    }),
    servicesCount: await (await getServiceSlugs()).length,
    page,
  };
  const props = await getGlobalStaticProps(
    { data },
    {
      pageTitle: page.title,
      pageDescription: page.meta_description,
      publishDate: page.date,
      pageImage: page.image,
    }
  );
  return { props };
}
